@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__media-container {
  position: relative;

  width: 100%;

  &[data-centralize-item='true'] {
    display: flex;
    justify-content: center;
  }

  &[data-is-full-content-size='true'] {
    .media-container-image {
      width: 100%;
    }
  }

  &[data-is-full-content-size='false'] {
    .media-container-image {
      width: auto;
    }
  }

  /* MEDIA CONTAINER IMAGE
  ========================================================================== */
  .media-container-image {
    max-width: 100%;
    height: auto;
    max-height: 100%;

    object-fit: cover;
  }

  /* EMBED CONTAINER
  ========================================================================== */
  .embed-container {
    position: relative;
    aspect-ratio: 16/9;

    width: 100%;

    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      border: none;
    }
  }

  /* POSTER CONTAINER
  ========================================================================== */
  .poster-container {
    display: grid;
    place-items: center;

    .media-trigger {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      border: none;

      transition: opacity ease $basic-transition;

      &:hover,
      &:focus {
        opacity: 0.9;
        transition: opacity ease $basic-transition;
      }

      .media-trigger-icon {
        position: absolute;
      }
    }
  }
}
