@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$list-padding: 4rem;
$list-margin: 2rem;

/* POST CONTENT
========================================================================== */
.organism__rich-text-section-container {
  position: relative;
  
  display: grid;

  /* FONT COLOR VARIATION
  ========================================================================== */
  &[data-is-legal="true"] {
    color: $c-gray-6;
  }

/* HEADERS
  ========================================================================== */
  h1, h2 {
    margin-bottom: $s-md;

    font-size: $text-size-small;
    font-weight: 600;
    line-height: $text-line-height-medium;

    @include gt-phone {
      font-size: $text-size-large;
      font-weight: 500;
      line-height: $text-line-height-large;
    }
  }

  h3 {
    margin-bottom: $s-s;

    font-size: $text-size-small;
    line-height: $text-line-height-medium;
    font-weight: 400;
  }

  /* P
  ========================================================================== */
  p {
    margin-bottom: $s-md;

    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  a {
    text-decoration: underline;
    transition: opacity ease $basic-transition;

    &:hover,
    &:focus {
      opacity: 0.7;
      transition: opacity ease $basic-transition;
    }
  }

  /* LISTA
  ========================================================================== */
  ul {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    padding-bottom: $s-lg;

    list-style: disc;

    li {
      position: relative;

      gap: $s-lg;

      margin-left: $s-md;
      padding-left: $s-s;

      font-size: $text-size-default;
      line-height: $text-line-height-medium;      

      &::marker {
        color: $c-primary;
        font-size: 1rem;
      }
      
    }
  }

  ol {
    padding-left: $list-padding;
    
    list-style-type: decimal;

    li {
      margin-left: $s-s;
    }
  }

}
