@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$media-query-min-size: 1281px;

.header-wrapper {
  z-index: $z-index-sky;
  position: sticky;
  top: 0;
  height: $main-header-height; // TODO: should we re-think it ?
  padding: $s-md 0;
  border-bottom: solid 1px $c-gray-3;
  background-color: $c-base-white;
  transition: height 0.3s linear 0.4s;

  &[data-is-rendering] {
    height: 100vh;

    .header-logo {
      // TODO: will probably have to set different distance to mobile and tablet
      transform: translate(550px, calc(50vh - 80%)) scale(2);

      .header-logo__image {
        animation: spin 4s linear infinite;
      }
    }

    .header-navigation {
      opacity: 0 !important;
    }
  }

  .header-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    font-size: 1.2rem;
    transition: transform 0.3s linear;
  }

  .header-logo__anchor {
    opacity: 1;
    transition: opacity ease $basic-transition;

    &:hover,
    &:focus {
      opacity: 0.8;
      transition: opacity ease $basic-transition;
    }
  }

  .header-container {
    display: flex;
    align-items: center;
    gap: $s-xxl;
  }

  .header-navigation {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    margin-left: auto;
    gap: $s-lg;

    opacity: 1;
    transition: opacity linear 0.2s 0.1s;

    @include mediaMin($media-query-min-size) {
      flex-direction: row;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  @include phone {
    border: none;
  }
}

@keyframes spin {
  from {
    transform: none;
  }
  to {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}
