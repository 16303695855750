@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.carousel-container {
  position: relative;

  /* DOTS
  ========================================================================== */
  [class^='slick-dots'] {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    gap: $s-lg;

    @include gt-tablet {
      width: 100%;
    }

    li {
      display: unset;

      width: unset;
      height: unset;

      margin-inline: unset !important;
      padding: 0;

      &:hover,
      &:focus {
        opacity: 0.6;
      }

      &[class^='slick-active'] {
        button {
          width: 4rem;

          background-color: $c-base-black;

          opacity: 1;

          [data-bg-color='black'] & {
            background-color: $c-base-white;
          }
        }
      }
    }

    button {
      width: 0.8rem;
      height: 0.8rem;

      padding: 0;

      border: none;
      border-radius: 6px;
      background-color: $c-base-black;
      color: transparent;

      line-height: 0;

      opacity: 0.6;

      transition: width ease $basic-transition;

      [data-bg-color='black'] & {
        background-color: $c-base-white;
      }
    }

    [class^='slick-pause-button'] {
      background-color: transparent !important;
    }
  }

  /* ARROWS
  ========================================================================== */
  [class^='slick-arrow'] {
    z-index: $z-index-jump;
    position: absolute;

    top: 50%;

    width: 1.5rem;
    height: 1.5rem;

    transform: rotate(45deg);

    border: none;

    color: transparent;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }

  [class*='slick-prev'] {
    display: block;

    left: $s-s;
    border-bottom: solid 0.15rem $c-base-black;

    border-left: solid 0.15rem $c-base-black;
  }

  [class*='slick-next'] {
    display: block;

    right: $s-s;
    border-top: solid 0.15rem $c-base-black;

    border-right: solid 0.15rem $c-base-black;
  }

  [class*='slick-disabled'] {
    cursor: default;

    opacity: 0.2;
  }
}
