@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$items-border-width: 4px;
$button-max-width: 26.5rem;

.banner-list-item {
  position: relative;

  align-items: center;

  &[data-content-layout-type="type-2"] {
    align-items: initial;
    justify-content: space-between;

    .banner-content-container {
      @include gt-tablet {
        justify-content: space-between;
      }
    }

    .banner-content-container__title {
      text-transform: none;
    }
  }

  &:not(:last-child) {
    margin-bottom: $s-xl;
    padding-bottom: $s-xl;

    border-bottom: $items-border-width solid $c-gray-3;
  }

  .banner-image-container {
    @include gt-tablet {
      grid-column: 1/7;
    }
  }

  .banner-content-container {
    display: flex;
    flex-direction: column;
    gap: $text-size-default;

    @include gt-tablet {
      grid-column: 7/-1;
    }
  }

  .banner-content-container__title {
    margin-bottom: $s-zs;
    text-transform: uppercase;
  }

  .banner-content-container__text {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    color: $c-gray-6;
  }

  .banner-content-container__button {
    width: 100%;

    @include gt-phone {
      max-width: $button-max-width;
    }
  }

  /* LAYOUT VARIANTS
  ========================================================================== */
  &[data-type='2'] {
    @include gt-phone {
      .banner-image-container {
        grid-column: 2 / span 2;
      }
      .banner-content-container {
        grid-column: 4 / span 8;
      }
    }

    @include phone {
      grid-row-gap: $s-md;
      .banner-image-container {
        margin: 0 auto;
      }
    }
  }
  &[data-type='3'] {
    align-items: flex-start;

    .banner-image-container {
      @include gt-tablet {
        grid-column: 1/5;
      }

      @include gt-phone {
        img {
          width: auto;
          max-width: max-content;
        }
      }
    }

    .banner-content-container {
      @include gt-tablet {
        grid-column: 6/-1;
      }
    }
  }

  &[data-type='4'] {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "image"
      "content";

    .banner-image-container {
      grid-area: image;
    }

    .banner-content-container {
      grid-area: content;
    }

    .banner-content-container__button {
      margin-top: $s-md;
    }

    @include gt-phone {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
      "content image ";
    }

    @include tablet {
      grid-template-columns: 1fr;
      grid-template-areas:
        "image"
        "content";
    }
  }
}
