@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

$size-small: 48px;
$size-medium: 72px;
$size-large: 96px;

.icon-play {
  position: relative;

  &[data-size='1'] {
    width: $size-small;
    height: $size-small;
  }

  &[data-size='2'] {
    width: $size-medium;
    height: $size-medium;
  }

  &[data-size='3'] {
    width: $size-small;
    height: $size-small;

    @include gt-phone {
      width: $size-large;
      height: $size-large;
    }
  }
}
