@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.cards-list {
  position: relative;  

  &.no-carousel {

    &.type-3 {
      gap: $s-lg;
    }

    @include phone {
      display: grid;
      gap: $s-xl;
    }
    
  }

  @include gt-tablet {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: $s-xxl $grid-gutter;

    &.type-2 {
      grid-template-columns: repeat(4,1fr);
    }

    &.type-3 {
      grid-template-columns: repeat(2,1fr);
    }
  }

  /* DOTS
  ========================================================================== */  
  [class^="slick-dots"] {
    position: static;
    
    display: flex !important; 
    align-items: center; 
    justify-content: center; 
    flex-direction: row;
    gap: $s-lg;

    margin-top: $s-xl;

    li {
      display: unset;
      
      margin: 0; 
      padding: 0; 
      
      width: unset; 
      height: unset;

      &[class^="slick-active"] {

        button {        
            width: 4rem;           
            
            background-color: $c-secondary; 
            
            opacity: 1;              
        }
  
      }

    }

    button {                
      padding: 0;
      
      width: .8rem; 
      height: .8rem; 
      
      line-height: 0; 
      color: transparent;

      background-color: $c-secondary; 
      
      border: none;
      border-radius: 6px;      
      opacity: .6;
      transition: width ease $basic-transition;                          
    }

  }    
  
}